<!--设备运维：设备列表：新增设备-->
<template>
  <div class="app-container page-container">
    <div class="section">
      <!--页头-->
      <div class="page-header">
        <div class="ph-left">
          <!--页面标题-->
          <h2 class="page-title">{{ $t('add_device') }}</h2>
        </div>
        <div class="ph-right">
          <el-button size="small" type="" icon="el-icon-back" @click="back">{{ $t('return') }}</el-button>
        </div>
      </div>
      <!--表单-->
      <div class="form-box" style="max-width: 1000px">
        <el-form
          ref="infoForm"
          :model="info"
          :rules="infoRules"
          :label-width="!isChineseLocale ? '120px' : '160px'"
          label-position="left"
        >
          <el-form-item :label="$t('device_type')" prop="typeIds">
            <el-cascader
              ref="myCascader"
              v-model.trim="info.typeIds"
              :placeholder="$t('please_select_device_type')"
              :disabled="isUpdate"
              :options="typeList"
              :props="{ expandTrigger: 'hover' }"
              style="width: 100%"
              size="medium"
              @change="handleChangeBrand"
            />
          </el-form-item>

          <el-form-item :label="$t('deviceName')" prop="name">
            <el-input
              v-model.trim="info.name"
              clearable
              :disabled="isUpdate"
              :placeholder="$t('Please_fill_in_name')"
              maxlength="32"
              size="medium"
            />
          </el-form-item>
          <el-form-item :label="$t('device_code')" prop="" style="max-width: 1000px">
            <el-input
                v-model="info.deviceCode"
                clearable
                :placeholder="$t('enter_device_code')"
                size="medium"
                maxlength="20"
            />
          </el-form-item>
          <el-form-item :label="$t('machine_serial_number')" prop="">
            <el-input
              v-model.trim="info.code"
              clearable
              :disabled="isUpdate"
              :placeholder="$t('enter_machine_serial_number')"
              size="medium"
            />
          </el-form-item>

          <el-form-item :label="$t('brand_model')" prop="brandIds">
            <el-cascader
              ref="myCascader1"
              v-model.trim="info.brandIds"
              :placeholder="$t('select_brand_model')"
              :disabled="isUpdate"
              :options="brandList"
              :props="{ expandTrigger: 'hover' }"
              style="width: 100%"
              size="medium"
              @change="handleChange"
            />
          </el-form-item>
<!--          <el-form-item v-if="infoTime" label="额定待机时长" prop="time">-->
<!--            <el-input-number :disabled="isUpdate" :min="5" :max="720" size="medium" v-model="info.time" :step="5"></el-input-number>-->
<!--            <span> 分钟</span>-->
<!--          </el-form-item>-->
          <!-- <el-form-item label="" v-if="brandbool" prop="brandStr">
            <el-input
              v-model.trim="info.brandStr"
              clearable
              :disabled="isUpdate"
              placeholder="请填写品牌型号"
              size="medium"
            />
          </el-form-item> -->

          <!--<el-form-item label="出厂日期" prop="factoryDate">
            <el-date-picker
              v-model.trim="info.factoryDate"
              type="date"
              value-format="yyyy-MM-dd"
              :disabled="isUpdate"
              placeholder="选择日期"
              style="width: 100%"
              size="medium"
            />
          </el-form-item>-->

          <!--<el-form-item label="安装位置" prop="address">
            <el-input
              v-model.trim="info.address"
              clearable
              :disabled="isUpdate"
              placeholder="请填写安装位置"
              size="medium"
            />
          </el-form-item>-->

          <!--<el-form-item label="neptune序列号"  prop="guGaoCode">
            <el-select v-model.trim="info.guGaoCode" placeholder="请填写neptune序列号" style="width: 100%">
              <el-option
                v-for="item in orders"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>-->

          <!--备注-->
          <el-form-item :label="$t('remark')" prop="remark">
            <el-input
              v-model.trim="info.remark"
              clearable
              type="textarea"
              :disabled="isUpdate"
              maxlength="100"
              :placeholder="$t('Please_fill_remarks')"
              :autosize="{ minRows: 4, maxRows: 4 }"
            />
          </el-form-item>

          <!--<el-form-item label="设备属性" prop="icollection">
            <el-select
              v-model.trim="info.icollection"
              clearable
              placeholder="请选择设备属性"
              style="width: 100%"
              @change="handleId"
            >
              <el-option
                v-for="(item, index) in attributeList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>-->

          <!--产品名称-->
          <!--<el-form-item v-if="inline === 1" label="产品名称" prop="productId">
            <el-select
              v-model.trim="info.productId"
              clearable
              placeholder="请选择产品名称"
              style="width: 100%"
            >
              <el-option
                v-for="item in productName"
                :key="item.id"
                :label="item.productName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>-->

          <!--工作幅面-->
          <!--<el-form-item style="width: 730px" label="工作幅面">
            <el-col :span="5">
              <el-form-item>
                <el-input-number v-model.trim="info.length" :min="0" :max="2000" :disabled="isUpdate" size="small" />
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <span class="mr-10 ml-10 font-24">*</span>
            </el-col>
            <el-col :span="5">
              <el-form-item>
                <el-input-number v-model.trim="info.width" :min="0" :max="2000" :disabled="isUpdate" size="small" />
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <span class="mr-10 ml-10 font-24"> - </span>
            </el-col>
            <el-col :span="5">
              <el-form-item>
                <el-input-number v-model.trim="info.length2" :min="0" :max="2000" :disabled="isUpdate" size="small" />
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <span class="mr-10 ml-10 font-24">*</span>
            </el-col>
            <el-col :span="5">
              <el-form-item>
                <el-input-number v-model.trim="info.width2" :min="0" :max="2000" :disabled="isUpdate" size="small" />
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <span class="note">（mm）</span>
            </el-col>
          </el-form-item>-->

          <!--运行速度-->
          <!--<el-form-item label="运行速度" prop="speed">
            <el-input-number
              v-model.trim="info.speed"
              :min="0"
              :max="60000"
              :disabled="isUpdate"
              size="small"
            />
            <span class="note">（张/小时）</span>
          </el-form-item>-->

          <!--每天运行工时-->
          <!--<el-form-item label="每天运行工时" prop="ratedWorkhours">
            <el-input-number
              v-model.trim="info.ratedWorkhours"
              :min="1"
              :max="24"
              :disabled="isUpdate"
              size="small"
            />
            <span class="note">（小时）</span>
          </el-form-item>-->

          <!--额定产能-->
          <!--<el-form-item label="额定产能">
            <el-input-number
              v-model.trim="info.ratedCapacity"
              :min="1"
              :max="1000000"
              :disabled="isUpdate"
              size="small"
            />
          </el-form-item>-->

          <!--版材厚度、承印物厚度-->
          <!--<el-form-item :label="label" style="width: 730px">
            <el-col :span="5">
              <el-form-item>
                <el-input-number v-model="info.thickness" :precision="2" :step="0.01" :min="0" :max="1" :disabled="isUpdate" size="small" />
              </el-form-item>
            </el-col>

            <el-col :span="1">
              <span class="mr-10 ml-10 font-24">*</span>
            </el-col>

            <el-col :span="5">
              <el-form-item>
                <el-input-number v-model="info.thickness2" :precision="2" :step="0.01" :min="0" :max="1" :disabled="isUpdate" size="small" />
              </el-form-item>
            </el-col>
            <span class="note">（mm）</span>
          </el-form-item>-->

          <!--<el-form-item label="保养人" prop="maintainPerson">
            <el-select
                @change="handleId"
              ref="maintainPerson"
              v-model.trim="info.maintainPerson"
              multiple
              :disabled="isUpdate"
              :placeholder="$t('select_maintenance_person')"
              style="width: 100%"
            >
              <el-option
                v-for="item in maintainPersons"
                :key="item.id"
                :label="item.cName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="点检人" prop="checkPerson">
            <el-select
              ref="checkPerson"
              v-model.trim="info.checkPerson"
              multiple
              :disabled="isUpdate"
              :placeholder="$t('select_inspection_person')"
              style="width: 100%"
            >
              <el-option
                v-for="item in checkPersons"
                :key="item.id"
                :label="item.cName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>-->
          <el-form-item :label="$t('class')" prop="groupIds" style="max-width: 1000px">
            <el-select
                v-model="info.groupIds"
                clearable
                multiple
                :placeholder="$t('please_select_team')"
                style="width: 100%"
            >
              <el-option
                  v-for="(item, index) in groupIds"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item class="widthTable" >
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" model="" width="50" />
              <el-table-column :label="$t('maintenance_strategy')" width="190">
                <template slot-scope="scope">
                  <span v-if="scope.row.type == 1">
                    {{ $t('day_maintainance') }}
                  </span>
                  <span v-if="scope.row.type == 2">
                    {{ $t('weeks_maintainance') }}
                  </span>
                  <span v-if="scope.row.type == 30">
                    {{ $t('half_months_maintainance') }}
                  </span>
                  <span v-if="scope.row.type == 3">
                    {{ $t('months_maintainance') }}
                  </span>
                  <span v-if="scope.row.type == 4">
                    {{ $t('quarter_maintainance') }}
                  </span>
                  <span v-if="scope.row.type == 5">
                    {{ $t('semi_annual_maintenance') }}
                  </span>
                  <span v-if="scope.row.type == 6">
                    {{ $t('annual_maintenance') }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column prop="name" :label="$t('time_range')" width="510">
                <template slot-scope="scope">
                  <span v-if="scope.row.type == 1">
                    {{ $t('daily') }}
                  </span>
                  <div v-if="scope.row.type == 2">
                    <el-select v-model.trim="scope.row.week" :placeholder="$t('please_select')">
                      <el-option
                        v-for="item in weeks"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </div>
                  <div v-if="scope.row.type == 30">
                    {{ $t('monthly') }}
                    <el-select v-model.trim="scope.row.day1" :placeholder="$t('please_select')" style="width: 100px">
                      <el-option
                          v-for="item in daysTime1"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                          @change="handleChangeValue(scope.row.day1)"
                      />
                    </el-select>
                    {{ $t('to') }}
                    <el-select v-model="scope.row.day2" @change="handleChangeValue2(scope.row.day2)" :placeholder="$t('please_select')" style="width: 100px">
                      <el-option
                          v-for="item in daysTime2"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                      />
                    </el-select>
                    {{ $t('day_day') }}
                  </div>
                  <div v-if="scope.row.type == 3">
                    {{ $t('monthly') }}
                    <el-select v-model.trim="scope.row.day1" :placeholder="$t('please_select')" style="width: 100px">
                      <el-option
                        v-for="item in days1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        @change="handleChangeValue(scope.row.day1)"
                      />
                    </el-select>
                    {{ $t('to') }}
                    <el-select v-model="scope.row.day2" @change="handleChangeValue2(scope.row.day2)" :placeholder="$t('please_select')" style="width: 100px">
                      <el-option
                        v-for="item in days2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('day_day') }}
                  </div>
                  <div v-if="scope.row.type == 4">
                    {{ $t('first_quarter_february') }}：
                    <el-select v-model.trim="scope.row.month1" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in month3"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('Month') }}
                    <el-select v-model.trim="scope.row.day1" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in days1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('to') }}
                    <el-select v-model.trim="scope.row.day2" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in days2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('day_day') }}
                  </div>
                  <div v-if="scope.row.type == 4">
                    {{ $t('second_quarter') }}：
                    <el-select v-model.trim="scope.row.month2" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in month4"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('Month') }}
                    <el-select v-model.trim="scope.row.day3" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in days1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('to') }}
                    <el-select v-model.trim="scope.row.day4" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in days2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('day_day') }}
                  </div>
                  <div v-if="scope.row.type == 4">
                    {{ $t('third_quarter') }}：
                    <el-select v-model.trim="scope.row.month3" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in month5"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('Month') }}
                    <el-select v-model.trim="scope.row.day5" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in days1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('to') }}
                    <el-select v-model.trim="scope.row.day6" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in days2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('day_day') }}
                  </div>
                  <div v-if="scope.row.type == 4">
                    {{ $t('fourth_quarter') }}：
                    <el-select v-model.trim="scope.row.month4" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in month6"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('Month') }}
                    <el-select v-model.trim="scope.row.day7" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in days1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('to') }}
                    <el-select v-model.trim="scope.row.day8" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in days2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('day_day') }}
                  </div>
                  <div v-if="scope.row.type == 5">
                    {{ $t('first_half') }}：
                    <el-select v-model.trim="scope.row.month1" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in month1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('Month') }}
                    <el-select v-model.trim="scope.row.day1" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in days1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('to') }}
                    <el-select v-model.trim="scope.row.day2" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in days2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('day_day') }}
                  </div>
                  <div v-if="scope.row.type == 5">
                    {{ $t('second_half') }}：
                    <el-select v-model.trim="scope.row.month2" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in month2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('Month') }}
                    <el-select v-model.trim="scope.row.day3" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in days1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('to') }}
                    <el-select v-model.trim="scope.row.day4" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in days2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('day_day') }}
                  </div>
                  <div v-if="scope.row.type == 6">
                    <el-select v-model.trim="scope.row.month" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in month"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('Month') }}
                    <el-select v-model.trim="scope.row.day1" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in days1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('to') }}
                    <el-select v-model.trim="scope.row.day2" :placeholder="$t('please_select')" style="width: 80px">
                      <el-option
                        v-for="item in days2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    {{ $t('day_day') }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                width="175"
                prop="address"
                align="center"
                :label="$t('strategy')"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-select
                    v-if="scope.row.type == 1"
                    v-model.trim="scope.row.templateId"
                    style="width: 100%"
                    :placeholder="$t('please_select')"
                    value=""
                  >
                    <el-option
                      v-for="item in dayTemplateList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>

                  <el-select
                    v-if="scope.row.type == 2"
                    v-model.trim="scope.row.templateId"
                    style="width: 100%"
                    :placeholder="$t('please_select')"
                    value=""
                  >
                    <el-option
                      v-for="item in weekTemplateList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 30"
                      v-model.trim="scope.row.templateId"
                      style="width: 100%"
                      :placeholder="$t('please_select')"
                      value=""
                  >
                    <el-option
                        v-for="item in halfMonthTemplateList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                    v-if="scope.row.type == 3"
                    v-model.trim="scope.row.templateId"
                    style="width: 100%"
                    :placeholder="$t('please_select')"
                    value=""
                  >
                    <el-option
                      v-for="item in monthTemplateList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>

                  <el-select
                    v-if="scope.row.type == 4"
                    v-model.trim="scope.row.templateId"
                    style="width: 100%"
                    :placeholder="$t('please_select')"
                    value=""
                  >
                    <el-option
                      v-for="item in quarterTemplateList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>

                  <el-select
                    v-if="scope.row.type == 5"
                    v-model.trim="scope.row.templateId"
                    style="width: 100%"
                    :placeholder="$t('please_select')"
                    value=""
                  >
                    <el-option
                      v-for="item in halfyearTemplateList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>

                  <el-select
                    v-if="scope.row.type == 6"
                    v-model.trim="scope.row.templateId"
                    style="width: 100%"
                    :placeholder="$t('please_select')"
                    value=""
                  >
                    <el-option
                      v-for="item in yearTemplateList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                  width="175"
                  prop="address"
                  align="center"
                  :label="$t('baoyangren')"
                  show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-select
                      v-if="scope.row.type == 1"
                      v-model="scope.row.maintainIds"
                      style="width: 100%"
                      multiple
                      :placeholder="$t('select_maintenance_person')"
                      value=""
                  >
                    <el-option
                        v-for="item in maintainPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 2"
                      v-model="scope.row.maintainIds"
                      style="width: 100%"
                      multiple
                      :placeholder="$t('select_maintenance_person')"
                      value=""
                  >
                    <el-option
                        v-for="item in maintainPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 30"
                      v-model="scope.row.maintainIds"
                      style="width: 100%"
                      multiple
                      :placeholder="$t('select_maintenance_person')"
                      value=""
                  >
                    <el-option
                        v-for="item in maintainPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 3"
                      v-model="scope.row.maintainIds"
                      style="width: 100%"
                      multiple
                      :placeholder="$t('select_maintenance_person')"
                      value=""
                  >
                    <el-option
                        v-for="item in maintainPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 4"
                      v-model="scope.row.maintainIds"
                      style="width: 100%"
                      multiple
                      :placeholder="$t('select_maintenance_person')"
                      value=""
                  >
                    <el-option
                        v-for="item in maintainPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 5"
                      v-model="scope.row.maintainIds"
                      style="width: 100%"
                      multiple
                      :placeholder="$t('select_maintenance_person')"
                      value=""
                  >
                    <el-option
                        v-for="item in maintainPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 6"
                      v-model="scope.row.maintainIds"
                      style="width: 100%"
                      multiple
                      :placeholder="$t('select_maintenance_person')"
                      value=""
                  >
                    <el-option
                        v-for="item in maintainPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                  width="175"
                  prop="address"
                  align="center"
                  :label="$t('inspector')"
                  show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-select
                      v-if="scope.row.type == 1"
                      v-model="scope.row.checkIds"
                      style="width: 100%"
                      multiple
                      :placeholder="$t('select_inspection_person')"
                      value=""
                  >
                    <el-option
                        v-for="item in checkPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 2"
                      v-model="scope.row.checkIds"
                      style="width: 100%"
                      multiple
                      :placeholder="$t('select_inspection_person')"
                      value=""
                  >
                    <el-option
                        v-for="item in checkPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 30"
                      v-model="scope.row.checkIds"
                      style="width: 100%"
                      multiple
                      :placeholder="$t('select_inspection_person')"
                      value=""
                  >
                    <el-option
                        v-for="item in checkPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 3"
                      v-model="scope.row.checkIds"
                      style="width: 100%"
                      multiple
                      :placeholder="$t('select_inspection_person')"
                      value=""
                  >
                    <el-option
                        v-for="item in checkPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 4"
                      v-model="scope.row.checkIds"
                      style="width: 100%"
                      multiple
                      :placeholder="$t('select_inspection_person')"
                      value=""
                  >
                    <el-option
                        v-for="item in checkPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 5"
                      v-model="scope.row.checkIds"
                      style="width: 100%"
                      multiple
                      :placeholder="$t('select_inspection_person')"
                      value=""
                  >
                    <el-option
                        v-for="item in checkPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                  <el-select
                      v-if="scope.row.type == 6"
                      v-model="scope.row.checkIds"
                      style="width: 100%"
                      multiple
                      :placeholder="$t('select_inspection_person')"
                      value=""
                  >
                    <el-option
                        v-for="item in checkPersons"
                        :key="item.id"
                        :label="item.cName"
                        :value="item.id"
                    />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                  width=""
                  prop="address"
                  align="center"
                  label=""
              >
                <template slot-scope="scope">
                  <el-checkbox v-if="scope.row.type == 1" v-model="scope.row.isGroup">{{ $t('class') }}</el-checkbox>
                  <el-checkbox v-if="scope.row.type == 2" v-model="scope.row.iBiweekly">{{ $t('bi_weekly') }}</el-checkbox>
<!--                  <el-checkbox v-if="scope.row.type == 3" :disabled="scope.row.disabled" v-model="scope.row.iHalfMoth">半月</el-checkbox>-->
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>

          <!--按钮组-->
          <el-form-item>
            <!--<el-button :loading="saveLoading" size="small" type="primary" @click="showOtherConfigClick">
              &lt;!&ndash;<svg-icon icon-class="set" />&ndash;&gt;
              设备配置
            </el-button>-->

            <el-button
              v-show="!isUpdate"
              :loading="saveLoading"
              size="small"
              type="primary"
              @click="saveDevice"
            >
              <!--<svg-icon icon-class="icon_save" />-->
              {{ $t('device_save') }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!--弹出框：设备配置-->
    <el-dialog
      v-if="showOtherConfig"
      :visible.sync="showOtherConfig"
      :title="$t('device_configuration')"
      :modal-append-to-body="false"
      :append-to-body="false"
      @close="closeCreateDialog"
    >
      <el-form
        ref="otherInfoForm"
        :model="dialogOtherConfigInfo"
        :rules="otherInfoRules"
        label-width="100px"
        class="mr-70"
      >
        <div v-show="isPrintConfig">
          <!--印刷单元-->
          <el-form-item label="印刷单元" prop="yinShuaDanYuan">
            <el-select
              v-model.trim="dialogOtherConfigInfo.yinShuaDanYuan"
              placeholder="请选择印刷单元"
              style="width: 100%"
              size="medium"
              clearable
            >
              <el-option
                v-for="item in otherConfigValue10"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <!--上光单元-->
          <el-form-item label="上光单元" prop="shangGuangDanyuan">
            <el-select
              v-model.trim="dialogOtherConfigInfo.shangGuangDanyuan"
              placeholder="请选择上光单元"
              style="width: 100%"
              size="medium"
              clearable
            >
              <el-option
                v-for="item in otherConfigValue4"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <!--干燥单元-->
          <el-form-item label="干燥单元" prop="ganZaoDanYuan">
            <el-select
              v-model.trim="dialogOtherConfigInfo.ganZaoDanYuan"
              placeholder="请选择干燥单元"
              style="width: 100%"
              size="medium"
              clearable
            >
              <el-option
                v-for="item in otherConfigValue4"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <!--加长收纸-->
          <el-form-item label="加长收纸" prop="jiaChangShouZhi">
            <el-select
              v-model.trim="dialogOtherConfigInfo.jiaChangShouZhi"
              placeholder="请选择加长收纸"
              style="width: 100%"
              size="medium"
              clearable
            >
              <el-option
                v-for="item in otherConfigValue4"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <!--翻转机构-->
          <el-form-item label="翻转机构" prop="fanZhuanJiGou">
            <el-select
              v-model.trim="dialogOtherConfigInfo.fanZhuanJiGou"
              placeholder="请选择翻转机构"
              style="width: 100%"
              size="medium"
              clearable
            >
              <el-option
                v-for="item in otherConfigValue"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <!--基座加高-->
          <el-form-item label="基座加高" prop="jiZuoJiaGao">
            <el-select
              v-model.trim="dialogOtherConfigInfo.jiZuoJiaGao"
              placeholder="请选择基座加高"
              style="width: 100%"
              size="medium"
              clearable
            >
              <el-option
                v-for="item in otherConfigValue"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <!--印刷方式-->
          <el-form-item label="印刷方式">
            <el-select
              v-model.trim="dialogOtherConfigInfo.yinShuaFangShi"
              placeholder="请选择印刷方式"
              style="width: 100%"
              size="medium"
              clearable
            >
              <el-option
                v-for="item in yinShuaFangShi"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <!--上光方式-->
          <el-form-item label="上光方式">
            <el-select
              v-model.trim="dialogOtherConfigInfo.shangGuangFangShi"
              placeholder="请选择上光方式"
              style="width: 100%"
              size="medium"
              clearable
            >
              <el-option
                v-for="item in shangGuangFangShi"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <!--干燥方式-->
          <el-form-item label="干燥方式">
            <el-select
              v-model.trim="dialogOtherConfigInfo.ganZaoFangShi"
              placeholder="请选择干燥方式"
              style="width: 100%"
              size="medium"
              clearable
            >
              <el-option
                v-for="item in ganZaoFangShi"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </div>

        <el-form-item label="配置">
          <el-select
            v-model.trim="dialogotherConfig"
            multiple
            placeholder="配置"
            :disabled="isUpdate"
            style="width: 100%"
          >
            <el-option
              v-for="item in configoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button :loading="createOtherConfigBtnLoading" type="primary" @click="saveOtherConfig">
          {{ $t('device_confirm') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API from '@/api'
import { mapGetters } from 'vuex'
import valid from '@/utils/validator'
import convert from '@/utils/convert'

export default {
  data() {
    return {
      inline: undefined, // 设备属性选择
      orders: [],
      label: '承印物厚度',
      printTypeStr: '',
      showOtherConfig: false,
      isPrintConfig: false,
      isUpdate: false,
      typeList: [],
      brandList: [],
      attributeList: [
        // 设备属性
        { value: 1, label: this.$t('data_acquisition_device') },
        { value: 0, label: this.$t('non_data_acquisition_device') }
      ],
      productName: [], // 产品名称下拉
      strategyList: [],
      saveLoading: false,
      createOtherConfigBtnLoading: false,
      configoptions: [],
      otherConfig: [],
      dialogotherConfig: [],
      maintainPersons: [],
      checkPersons: [],
      trategyInfo: [],
      dayTemplateList: [],
      weekTemplateList: [],
      halfMonthTemplateList: [],
      monthTemplateList: [],
      quarterTemplateList: [],
      halfyearTemplateList: [],
      yearTemplateList: [],
      brandbool: false,
      info: {
        deviceCode: '',
        guGaoCode: '',
        code: '',
        id: '',
        printType: '',
        deviceType: '',
        typeIds: [],
        brandIds: [],
        groupIds: [],
        // productId: '', // 产品id
        brand: '',
        brandStr: '',
        series: '',
        model: '',
        infoName: '',
        factoryDate: '',
        name: '',
        remark: '',
        address: '',
        otherConfig: '',
        printConfig: '',
        ratedCapacity: 0,
        // ratedWorkhours: 1,
        // length: 0,
        length2: 0,
        width: 0,
        width2: 0,
        // speed: 0,
        thickness: 0,
        thickness2: 0,
        strategy: '',
        maintainPerson: [],
        checkPerson: [],
        // icollection: undefined,
        teamId: '',
        time: 30
      },
      infoTime: '',
      infoRules: {
        name: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              valid.checkNull(rule, value, callback, this.$t('enter_device_name'), false)
              valid.checkBybite(rule, value, callback, this.$t('device_name_characters'))
            }
          }
        ],
        code: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              valid.checkNull(rule, value, callback, this.$t('please_enter_machine_serial_number'), false)
              valid.checkByRegex(rule, value, callback, /^.{1,20}$/, this.$t('not_exceed_characters'))
            }
          }
        ],
        typeIds: [
          {
            required: true,
            message: this.$t('please_select_device_type'),
            trigger: 'change'
          }
        ],
        brandIds: [
          {
            required: true,
            message: this.$t('select_brand_model'),
            trigger: 'change'
          }
        ],
        groupIds:[
          {
            required: true,
            message: this.$t('please_select_team'),
            trigger: 'change'
          }
        ],
        time: [
          { required: true, message: this.$t('enter_rated_standby_time_min'), trigger: 'blur' },
          { pattern: /[1-9]\d*/, max: 720, message: this.$t('positive_integer'), trigger: 'change' }

        ],
        icollection: [
          {
            required: true,
            message: '请选择设备属性',
            trigger: 'blur'
          }
        ],
        brandStr: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              var success = true
              if (this.brandbool) {
                if (value) {
                  if (value.length === 0 || value.length > 20) {
                    success = false
                  }
                }
              }
              if (success) {
                callback()
              } else {
                callback(new Error(this.$t('enter_brand_model_within_characters')))
              }
            }
          }
        ],
        // factoryDate: [
        //   {
        //     required: true,
        //     trigger: 'change',
        //     validator: (rule, value, callback) => {
        //       valid.checkSelect(rule, value, callback, '请选择出厂日期')
        //     }
        //   }
        // ],
        address: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              valid.checkNull(rule, value, callback, '请填写安装位置', false)
              valid.checkByRegex(rule, value, callback, /^.{1,20}$/, '安装位置不超过20个字')
            }
          }
        ],
        guGaoCode: [
          {
            required: false,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              valid.checkByRegex(rule, value, callback, /^.{0,30}$/, 'neptune序列号不超过30个字')
            }
          }
        ],
        remark: [
          {
            required: false,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              valid.checkComment(rule, value, callback, 100, this.$t('remarks_should_not_exceed_characters'))
            }
          }
        ],
        maintainPerson: [
          {
            type: 'array',
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              valid.checkMultiSelect(rule, value, callback, this.$t('select_maintenance_person'))
            }
          }
        ],
        checkPerson: [
          {
            type: 'array',
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              valid.checkMultiSelect(rule, value, callback, this.$t('select_inspection_person'))
            }
          }
        ],
        speed: [
          {
            required: true,
            message: '请填写运行速度',
            trigger: 'blur'
          }
        ],
        ratedWorkhours: [
          {
            required: true,
            message: '请填写每天运行工时',
            trigger: 'blur'
            /*validator: (rule, value, callback) => {
              var success = true
              if (value > 24 || value < 1) {
                success = false
              }
              if (success) {
                callback()
              } else {
                callback(new Error('请正确填写每天运行工时'))
              }
            }*/
          }
        ],
        productId: [
          {
            required: true,
            message: '请选择产品名称',
            trigger: 'blur'
          }
        ]
      },
      otherInfoRules: {
        yinShuaDanYuan: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              valid.checkSelect(rule, value, callback, '请配置印刷单元')
            }
          }
        ],
        shangGuangDanyuan: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              valid.checkSelect(rule, value, callback, '请配置上光单元')
            }
          }
        ],
        ganZaoDanYuan: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              valid.checkSelect(rule, value, callback, '请配置干燥单元')
            }
          }
        ],
        jiaChangShouZhi: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              valid.checkSelect(rule, value, callback, '请配置加长收纸')
            }
          }
        ],
        fanZhuanJiGou: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              valid.checkSelect(rule, value, callback, '请配置反转机构')
            }
          }
        ],
        jiZuoJiaGao: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              valid.checkSelect(rule, value, callback, '请配置基座加高')
            }
          }
        ]
      },
      otherConfigValue: [
        { id: '是', name: '是' },
        { id: '否', name: '否' }
      ],
      otherConfigValue10: [
        { id: '1', name: '1' },
        { id: '2', name: '2' },
        { id: '3', name: '3' },
        { id: '4', name: '4' },
        { id: '5', name: '5' },
        { id: '6', name: '6' },
        { id: '7', name: '7' },
        { id: '8', name: '8' },
        { id: '9', name: '9' },
        { id: '10', name: '10' }
      ],
      otherConfigValue4: [
        { id: '0', name: '0' },
        { id: '1', name: '1' },
        { id: '2', name: '2' },
        { id: '3', name: '3' },
        { id: '4', name: '4' }
      ],
      yinShuaFangShi: [
        { id: '普通', name: '普通' },
        { id: 'UV', name: 'UV' },
        { id: '两用', name: '两用' }
      ],
      shangGuangFangShi: [
        { id: '水性', name: '水性' },
        { id: 'UV', name: 'UV' }
      ],
      ganZaoFangShi: [
        { id: '传统', name: '传统' },
        { id: 'IR', name: 'IR' },
        { id: 'UV', name: 'UV' }
      ],
      dialogOtherConfigInfo: {
        yinShuaDanYuan: '',
        shangGuangDanyuan: '',
        ganZaoDanYuan: '',
        jiaChangShouZhi: '',
        fanZhuanJiGou: '',
        jiZuoJiaGao: '',
        yinShuaFangShi: '',
        shangGuangFangShi: '',
        ganZaoFangShi: ''
      },
      otherConfigInfo: {
        yinShuaDanYuan: '',
        shangGuangDanyuan: '',
        ganZaoDanYuan: '',
        jiaChangShouZhi: '',
        fanZhuanJiGou: '',
        jiZuoJiaGao: '',
        yinShuaFangShi: '',
        shangGuangFangShi: '',
        ganZaoFangShi: ''
      },
      tableData: [
        {
          type: 1,
          templateId: '',
          maintainIds: [],
          checkIds: [],
          isGroup: false
        },
        {
          type: 2,
          week: 1,
          templateId: '',
          maintainIds: [],
          checkIds: [],
          iBiweekly: false
        },
        {
          type: 30,
          day1: 1,
          day2: 16,
          templateId: '',
          codeList: [],
          maintainIds: '',
          checkIds: [],
          iHalfMoth: false,
          disabled: false
        },
        {
          type: 3,
          day1: 1,
          day2: 2,
          templateId: '',
          maintainIds: [],
          checkIds: [],
          iHalfMoth: false,
          disabled: false
        },
        {
          type: 4,
          month1: 1,
          month2: 4,
          month3: 7,
          month4: 10,
          day1: 1,
          day2: 2,
          day3: 1,
          day4: 2,
          day5: 1,
          day6: 2,
          day7: 1,
          day8: 2,
          templateId: '',
          maintainIds: [],
          checkIds: []
        },
        {
          type: 5,
          month1: 1,
          month2: 7,
          day1: 1,
          day2: 2,
          day3: 1,
          day4: 2,
          templateId: '',
          maintainIds: [],
          checkIds: []
        },
        {
          type: 6,
          month: 1,
          day1: 1,
          day2: 2,
          templateId: '',
          maintainIds: [],
          checkIds: []
        }
      ],
      multipleSelection: [],
      weeks: [
        {
          value: 1,
          label: this.$t('monday')
        },
        {
          value: 2,
          label: this.$t('tuesday')
        },
        {
          value: 3,
          label: this.$t('wednesday')
        },
        {
          value: 4,
          label: this.$t('thursday')
        },
        {
          value: 5,
          label: this.$t('friday')
        },
        {
          value: 6,
          label: this.$t('saturday')
        },
        {
          value: 7,
          label: this.$t('sunday')
        }
      ],
      templateList: [],
      days1: [
        {
          value: 1,
          label: 1
        },
        {
          value: 2,
          label: 2
        },
        {
          value: 3,
          label: 3
        },
        {
          value: 4,
          label: 4
        },
        {
          value: 5,
          label: 5
        },
        {
          value: 6,
          label: 6
        },
        {
          value: 7,
          label: 7
        },
        {
          value: 8,
          label: 8
        },
        {
          value: 9,
          label: 9
        },
        {
          value: 10,
          label: 10
        },
        {
          value: 11,
          label: 11
        },
        {
          value: 12,
          label: 12
        },
        {
          value: 13,
          label: 13
        },
        {
          value: 14,
          label: 14
        },
        {
          value: 15,
          label: 15
        },
        {
          value: 16,
          label: 16
        },
        {
          value: 17,
          label: 17
        },
        {
          value: 18,
          label: 18
        },
        {
          value: 19,
          label: 19
        },
        {
          value: 20,
          label: 20
        },
        {
          value: 21,
          label: 21
        },
        {
          value: 22,
          label: 22
        },
        {
          value: 23,
          label: 23
        },
        {
          value: 24,
          label: 24
        },
        {
          value: 25,
          label: 25
        },
        {
          value: 26,
          label: 26
        },
        {
          value: 27,
          label: 27
        },
        {
          value: 28,
          label: 28
        }
      ],
      days2: [
        {
          value: 1,
          label: 1
        },
        {
          value: 2,
          label: 2
        },
        {
          value: 3,
          label: 3
        },
        {
          value: 4,
          label: 4
        },
        {
          value: 5,
          label: 5
        },
        {
          value: 6,
          label: 6
        },
        {
          value: 7,
          label: 7
        },
        {
          value: 8,
          label: 8
        },
        {
          value: 9,
          label: 9
        },
        {
          value: 10,
          label: 10
        },
        {
          value: 11,
          label: 11
        },
        {
          value: 12,
          label: 12
        },
        {
          value: 13,
          label: 13
        },
        {
          value: 14,
          label: 14
        },
        {
          value: 15,
          label: 15
        },
        {
          value: 16,
          label: 16
        },
        {
          value: 17,
          label: 17
        },
        {
          value: 18,
          label: 18
        },
        {
          value: 19,
          label: 19
        },
        {
          value: 20,
          label: 20
        },
        {
          value: 21,
          label: 21
        },
        {
          value: 22,
          label: 22
        },
        {
          value: 23,
          label: 23
        },
        {
          value: 24,
          label: 24
        },
        {
          value: 25,
          label: 25
        },
        {
          value: 26,
          label: 26
        },
        {
          value: 27,
          label: 27
        },
        {
          value: 28,
          label: 28
        },
        {
          value: 29,
          label: 29
        },
        {
          value: 30,
          label: 30
        },
        {
          value: 31,
          label: 31
        }
      ],
      daysTime1:[
        {
          value: 1,
          label: 1
        },
        {
          value: 2,
          label: 2
        },
        {
          value: 3,
          label: 3
        },
        {
          value: 4,
          label: 4
        },
        {
          value: 5,
          label: 5
        },
        {
          value: 6,
          label: 6
        },
        {
          value: 7,
          label: 7
        },
        {
          value: 8,
          label: 8
        },
        {
          value: 9,
          label: 9
        },
        {
          value: 10,
          label: 10
        },
        {
          value: 11,
          label: 11
        },
        {
          value: 12,
          label: 12
        },
        {
          value: 13,
          label: 13
        },
        {
          value: 14,
          label: 14
        },
      ],
      daysTime2: [
        {
          value: 16,
          label: 16
        },
        {
          value: 17,
          label: 17
        },
        {
          value: 18,
          label: 18
        },
        {
          value: 19,
          label: 19
        },
        {
          value: 20,
          label: 20
        },
        {
          value: 21,
          label: 21
        },
        {
          value: 22,
          label: 22
        },
        {
          value: 23,
          label: 23
        },
        {
          value: 24,
          label: 24
        },
        {
          value: 25,
          label: 25
        },
        {
          value: 26,
          label: 26
        },
        {
          value: 27,
          label: 27
        },
        {
          value: 28,
          label: 28
        },
        {
          value: 29,
          label: 29
        },
        {
          value: 30,
          label: 30
        },
        {
          value: 31,
          label: 31
        }
      ],
      month: [
        {
          value: 1,
          label: 1
        },
        {
          value: 2,
          label: 2
        },
        {
          value: 3,
          label: 3
        },
        {
          value: 4,
          label: 4
        },
        {
          value: 5,
          label: 5
        },
        {
          value: 6,
          label: 6
        },
        {
          value: 7,
          label: 7
        },
        {
          value: 8,
          label: 8
        },
        {
          value: 9,
          label: 9
        },
        {
          value: 10,
          label: 10
        },
        {
          value: 11,
          label: 11
        },
        {
          value: 12,
          label: 12
        }
      ],
      month1: [
        {
          value: 1,
          label: 1
        },
        {
          value: 2,
          label: 2
        },
        {
          value: 3,
          label: 3
        },
        {
          value: 4,
          label: 4
        },
        {
          value: 5,
          label: 5
        },
        {
          value: 6,
          label: 6
        }
      ],
      month2: [
        {
          value: 7,
          label: 7
        },
        {
          value: 8,
          label: 8
        },
        {
          value: 9,
          label: 9
        },
        {
          value: 10,
          label: 10
        },
        {
          value: 11,
          label: 11
        },
        {
          value: 12,
          label: 12
        }
      ],
      month3: [
        {
          value: 1,
          label: 1
        },
        {
          value: 2,
          label: 2
        },
        {
          value: 3,
          label: 3
        }
      ],
      month4: [
        {
          value: 4,
          label: 4
        },
        {
          value: 5,
          label: 5
        },
        {
          value: 6,
          label: 6
        }
      ],
      month5: [
        {
          value: 7,
          label: 7
        },
        {
          value: 8,
          label: 8
        },
        {
          value: 9,
          label: 9
        }
      ],
      month6: [
        {
          value: 10,
          label: 10
        },
        {
          value: 11,
          label: 11
        },
        {
          value: 12,
          label: 12
        }
      ],
      form: {
        name: '',
        page: 1,
        count: 100000000,
        teamId: ''
      },
      groupIds: [] // 班组
    }
  },
  computed: {
    ...mapGetters(['buttons']),
    isChineseLocale() {
      return localStorage.getItem('locale') === 'en';
    }
  },
  mounted() {
    // 从印刷管家首页点击 '选择保养人、选择点检人'，自动弹出下拉框
    const ref = this.$route.query.ref
    // console.log(ref)
    if (ref) {
      this.$refs[ref].visible = true
    }
  },
  created() {
    // 获取设备类型
    API.getDeviceConfigList()
      .then(response => {
        this.typeList = convert.arr2cascader_deviceconfig(response.message.data)
      })
      .catch(err => {
        this.$message({
          message: err,
          type: 'error'
        })
      })
    API.getClassDate(this.form).then((response) => {
      this.groupIds = response.message.data.list;
    });
    // 保养人下拉
    API.getTeamUserXiaLa({ type: 1 })
      .then(response => {
        this.maintainPersons = response.message.data
      })
      .catch(err => {
        this.$message({
          message: err,
          type: 'error'
        })
      })
    // 点检下人拉
    API.getTeamUserXiaLa({ type: 2 })
      .then(response => {
        this.checkPersons = response.message.data
      })
      .catch(err => {
        this.$message({
          message: err,
          type: 'error'
        })
      })
    // 产品名称下拉
    /*API.prodectList()
      .then(res => {
        console.log(res)
        this.productName = res
      })
      .catch(err => {
        this.$message({
          message: err,
          type: 'error'
        })
      })*/

    // 查询设备序略号列表
    /* API.getDeviceOrderList()
      .then(response => {
        this.orders = response.message.data.list
      })
      .catch(err => {
        this.$message({
          message: err,
          type: 'error'
        })
      })*/
    this.handleChangeValue()
    this.handleChangeValue2()
  },
  methods: {
    // 设备属性选择时
    handleId(val) {
      this.inline = val
    },
    handleChangeValue(val) {
      // console.log(val)
      if (val>15 || this.tableData[2].day2 <= 15) {
        this.tableData[2].disabled = true
        this.tableData[2].iHalfMoth = false
      } else {
        this.tableData[2].disabled = false
      }
      // if (this.tableData[2].day2 < 15) {
      //   this.tableData[2].disabled = true
      // } else {
      //   this.tableData[2].disabled = false
      // }
    },
    handleChangeValue2(val) {
      // console.log(this.tableData[2].day1)
      if (val <= 15 || this.tableData[2].day1 > 15) {
        this.tableData[2].disabled = true
        this.tableData[2].iHalfMoth = false
      } else {
        this.tableData[2].disabled = false
      }
      if (!val) {
        if (this.tableData[2].day2 < 15) {
          this.tableData[2].disabled = true
          this.tableData[2].iHalfMoth = false
        } else {
          this.tableData[2].disabled = false
        }
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    back() {
      this.$router.go(-1)
    },
    saveOtherConfig() {
      let flag = true
      if (this.printTypeStr === this.$t('yinshua_device')) {
        this.$refs['otherInfoForm'].validate(v => {
          if (!v) {
            flag = false
            return false
          }
        })
      }
      if (flag) {
        this.showOtherConfig = false
        this.otherConfigInfo = this.dialogOtherConfigInfo
        this.info.printConfig = JSON.stringify(this.otherConfigInfo)
        this.otherConfig = this.dialogotherConfig
      }
    },
    closeCreateDialog() {
      this.dialogOtherConfigInfo = {
        yinShuaDanYuan: '',
        shangGuangDanyuan: '',
        ganZaoDanYuan: '',
        jiaChangShouZhi: '',
        fanZhuanJiGou: '',
        jiZuoJiaGao: '',
        yinShuaFangShi: '',
        shangGuangFangShi: '',
        ganZaoFangShi: ''
      }
      this.dialogotherConfig = []
    },
    showOtherConfigClick() {
      if (this.info.deviceType === '' || this.info.deviceType === undefined) {
        this.$message({
          message: this.$t('Please_select_the_device_type_first'),
          type: 'error'
        })
      } else {
        if (this.printTypeStr === this.$t('yinshua_device')) {
          this.isPrintConfig = true
        } else {
          this.isPrintConfig = false
        }
        this.dialogOtherConfigInfo = this.otherConfigInfo
        this.dialogotherConfig = this.otherConfig
        this.showOtherConfig = true
      }
    },
    handleChangeBrand() {
      this.tableData.map(item => {
        item.templateId = ''
      })
      this.brandbool = false
      var list = this.info.typeIds
      if (list[0] === '630a511d-42a6-4ccc-a495-1afb3debe272') {
        this.infoTime = list[0]
        this.printingEquipment = list[0]
        this.dailyMaintenance = ''
        this.weekMaintenance = '' // 周保养
        this.monthlyMaintenance = '' // 月保养
        this.quarterlyMaintenance = '' // 季度保养
        this.halfYearMaintenance = '' // 半年度保养
        this.yearMaintenance = ''
      }else {
        this.infoTime = ''
        this.dailyMaintenance = ''
        this.weekMaintenance = '' // 周保养
        this.monthlyMaintenance = '' // 月保养
        this.quarterlyMaintenance = '' // 季度保养
        this.halfYearMaintenance = '' // 半年度保养
        this.yearMaintenance = ''
      }
      this.info.brandIds = []
      this.info.brandStr = ''
      this.info.brand = ''
      this.info.series = ''
      this.info.model = ''
      // 获取设备类型不包含品牌类型
      API.getDeviceBrandList({ printTypeId: list[1] })
        .then(response => {
          this.brandList = response.message.data
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          })
        })
      // 获取设备配置
      API.getDeviceConfig({ id: list[1] }).then(response => {
        // console.log(response)
        this.configoptions = response.message.data
      })
      // 获取策略
      API.getTemplateListByType({ deviceId: list[1] }).then(response => {
        this.templateList = response.message.data
        this.dayTemplateList = []
        this.weekTemplateList = []
        this.monthTemplateList = []
        this.halfMonthTemplateList = []
        this.quarterTemplateList = []
        this.halfyearTemplateList = []
        this.yearTemplateList = []
        response.message.data.forEach(item => {
          if (item.type === 1) {
            this.dayTemplateList.push(item)
          } else if (item.type === 2) {
            this.weekTemplateList.push(item)
          } else if (item.type === 3) {
            this.monthTemplateList.push(item)
          } else if (item.type === 4) {
            this.quarterTemplateList.push(item)
          } else if (item.type === 5) {
            this.halfyearTemplateList.push(item)
          } else if (item.type === 6) {
            this.yearTemplateList.push(item)
          } else if (item.type === 30) {
            this.halfMonthTemplateList.push(item)
          }
        })
      })
    },
    handleChange() {
      // 选择的品牌型号
      const labels2 = this.$refs['myCascader1'].getCheckedNodes()[0].pathLabels
      // console.log(labels2)
      // 选择的设备类型
      const labels = this.$refs['myCascader'].getCheckedNodes()[0].pathLabels
      // console.log(labels)
      var brand = labels2[0]
      this.info.series = this.info.brandIds[1]
      this.info.model = this.info.brandIds[2]
      // if (brand !== '其它') {
      //
      //   this.brandbool = false
      //   this.info.brandStr = ''
      // } else {
      //   this.info.infoName = labels.join('/')
      //   this.info.series = ''
      //   this.info.model = ''
      //   this.brandbool = true
      // }
      this.printTypeStr = labels[0]
      if (this.printTypeStr === this.$t('yinqian_device')) {
        this.label = '版材厚度'
      } else {
        this.label = '承印物厚度'
      }

      var list = this.info.typeIds
      this.info.printType = list[0]
      this.info.deviceType = list[1]
      this.info.brand = this.info.brandIds[0]
    },
    saveDevice() {
      this.$refs['infoForm'].validate(v => {
        if (v) {
          if (valid.isNullOrEmpty(this.info.printType)) {
            return this.$message.error(this.$t('please_select_device_type'))
          }
          // if (this.printTypeStr === '印刷设备') {
          //   if (valid.isNullOrEmpty(this.info.printConfig)) {
          //     return this.$message.error('请配置设备信息')
          //   }
          // }
          this.info.otherConfig = this.otherConfig.join()
          this.saveLoading = true
          var day = ''
          var week = ''
          let halfmonth = ''
          var month = ''
          var quarter = ''
          var halfyaer = ''
          var year = ''
          var dayTemplateId = ''
          var weekTemplateId = ''
          var halfmonthTemplateId = ''
          var monthTemplateId = ''
          var quarterTemplateId = ''
          var halfyaerTemplateId = ''
          var yearTemplateId = ''
          this.trategyInfo = [].concat(JSON.parse(JSON.stringify(this.multipleSelection)))
          // for (var i = 0; i < this.multipleSelection.length; i++) {
          //   this.trategyInfo.push(this.multipleSelection[i])
          // }
          // eslint-disable-next-line no-redeclare
          for (var i = 0; i < this.trategyInfo.length; i++) {
            if (this.trategyInfo[i].type === 1) {
              if (!this.trategyInfo[i].templateId || this.trategyInfo[i].templateId.length === 0) {
                this.saveLoading = false
                return this.$message.error(this.$t('daily_maintenance_strategy_template'))
              }
              if (!this.trategyInfo[i].maintainIds || this.trategyInfo[i].maintainIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('daily_maintenance_person'));
              }
              if (!this.trategyInfo[i].checkIds || this.trategyInfo[i].checkIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('daily_inspection_person'));
              }
              this.trategyInfo[i].maintainIds = this.trategyInfo[i].maintainIds.join(',');
              this.trategyInfo[i].checkIds = this.trategyInfo[i].checkIds.join(',');
              if (this.trategyInfo[i].isGroup=== true) {
                this.trategyInfo[i].isGroup = 1
              } else {
                this.trategyInfo[i].isGroup = 0
              }
              dayTemplateId = this.trategyInfo[i].templateId
              this.$delete(this.trategyInfo[i], 'templateId')
              this.$delete(this.trategyInfo[i], 'type')
              day = JSON.stringify(this.trategyInfo[i])
            } else if (this.trategyInfo[i].type === 2) {
              if (!this.trategyInfo[i].templateId || this.trategyInfo[i].templateId.length === 0) {
                this.saveLoading = false
                return this.$message.error(this.$t('weekly_maintenance_strategy_template'))
              }
              if (!this.trategyInfo[i].maintainIds || this.trategyInfo[i].maintainIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('weekly_maintenance_person'));
              }
              if (!this.trategyInfo[i].checkIds || this.trategyInfo[i].checkIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('weekly_inspection_person'));
              }
              this.trategyInfo[i].maintainIds = this.trategyInfo[i].maintainIds.join(',');
              this.trategyInfo[i].checkIds = this.trategyInfo[i].checkIds.join(',');
              weekTemplateId = this.trategyInfo[i].templateId
              this.$delete(this.trategyInfo[i], 'templateId')
              this.$delete(this.trategyInfo[i], 'type')
              week = JSON.stringify(this.trategyInfo[i])
            } else if (this.trategyInfo[i].type === 30) {
              if (!this.trategyInfo[i].templateId || this.trategyInfo[i].templateId.length === 0) {
                this.saveLoading = false
                return this.$message.error(this.$t('semi_monthly_maintenance_strategy_template'))
              }
              if (!this.trategyInfo[i].maintainIds || this.trategyInfo[i].maintainIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('semi_monthly_maintenance_person'));
              }
              if (!this.trategyInfo[i].checkIds || this.trategyInfo[i].checkIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('semi_monthly_inspection_person'));
              }
              this.trategyInfo[i].maintainIds = this.trategyInfo[i].maintainIds.join(',');
              this.trategyInfo[i].checkIds = this.trategyInfo[i].checkIds.join(',');
              if (this.trategyInfo[i].day1 > this.trategyInfo[i].day2) {
                this.saveLoading = false
                return this.$message.error(this.$t('Semi_monthly_maintenance_range_selection'))
              }
              halfmonthTemplateId = this.trategyInfo[i].templateId
              this.$delete(this.trategyInfo[i], 'templateId')
              this.$delete(this.trategyInfo[i], 'type')
              halfmonth = JSON.stringify(this.trategyInfo[i])
            }else if (this.trategyInfo[i].type === 3) {
              if (!this.trategyInfo[i].templateId || this.trategyInfo[i].templateId.length === 0) {
                this.saveLoading = false
                return this.$message.error(this.$t('maintenance_strategy_template'))
              }
              if (!this.trategyInfo[i].maintainIds || this.trategyInfo[i].maintainIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('monthly_maintenance_person'));
              }
              if (!this.trategyInfo[i].checkIds || this.trategyInfo[i].checkIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('monthly_inspection_person'));
              }
              this.trategyInfo[i].maintainIds = this.trategyInfo[i].maintainIds.join(',');
              this.trategyInfo[i].checkIds = this.trategyInfo[i].checkIds.join(',');
              if (this.trategyInfo[i].day1 > this.trategyInfo[i].day2) {
                this.saveLoading = false
                return this.$message.error(this.$t('monthly_maintenance_range_selection'))
              }
              monthTemplateId = this.trategyInfo[i].templateId
              this.$delete(this.trategyInfo[i], 'templateId')
              this.$delete(this.trategyInfo[i], 'type')
              month = JSON.stringify(this.trategyInfo[i])
            } else if (this.trategyInfo[i].type === 4) {
              if (!this.trategyInfo[i].templateId || this.trategyInfo[i].templateId.length === 0) {
                this.saveLoading = false
                return this.$message.error(this.$t('quarterly_maintenance_strategy_template'))
              }
              if (!this.trategyInfo[i].maintainIds || this.trategyInfo[i].maintainIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('quarterly_maintenance_person'));
              }
              if (!this.trategyInfo[i].checkIds || this.trategyInfo[i].checkIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('quarterly_inspection_person'));
              }
              this.trategyInfo[i].maintainIds = this.trategyInfo[i].maintainIds.join(',');
              this.trategyInfo[i].checkIds = this.trategyInfo[i].checkIds.join(',');
              if (this.trategyInfo[i].day1 > this.trategyInfo[i].day2) {
                this.saveLoading = false
                return this.$message.error(this.$t('quarterly_maintenance_first_quarter_time'))
              }
              if (this.trategyInfo[i].day3 > this.trategyInfo[i].day4) {
                this.saveLoading = false
                return this.$message.error(this.$t('quarterly_maintenance_second_quarter_time'))
              }
              if (this.trategyInfo[i].day5 > this.trategyInfo[i].day6) {
                this.saveLoading = false
                return this.$message.error(this.$t('quarterly_maintenance_third_quarter_time'))
              }
              if (this.trategyInfo[i].day7 > this.trategyInfo[i].day8) {
                this.saveLoading = false
                return this.$message.error(this.$t('quarterly_maintenance_fourth_quarter_time'))
              }
              quarterTemplateId = this.trategyInfo[i].templateId
              this.$delete(this.trategyInfo[i], 'templateId')
              this.$delete(this.trategyInfo[i], 'type')
              quarter = JSON.stringify(this.trategyInfo[i])
            } else if (this.trategyInfo[i].type === 5) {
              if (!this.trategyInfo[i].templateId || this.trategyInfo[i].templateId.length === 0) {
                this.saveLoading = false
                return this.$message.error(this.$t('semi_annual_maintenance_strategy_template'))
              }
              if (!this.trategyInfo[i].maintainIds || this.trategyInfo[i].maintainIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('semi_annual_maintenance_person'));
              }
              if (!this.trategyInfo[i].checkIds || this.trategyInfo[i].checkIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('semi_annual_inspection_person'));
              }
              this.trategyInfo[i].maintainIds = this.trategyInfo[i].maintainIds.join(',');
              this.trategyInfo[i].checkIds = this.trategyInfo[i].checkIds.join(',');
              if (this.trategyInfo[i].day1 > this.trategyInfo[i].day2) {
                this.saveLoading = false
                return this.$message.error(this.$t('semi_annual_maintenance_first_half_time'))
              }
              if (this.trategyInfo[i].day3 > this.trategyInfo[i].day4) {
                this.saveLoading = false
                return this.$message.error(this.$t('semi_annual_maintenance_second_half_time'))
              }
              halfyaerTemplateId = this.trategyInfo[i].templateId
              this.$delete(this.trategyInfo[i], 'templateId')
              this.$delete(this.trategyInfo[i], 'type')
              halfyaer = JSON.stringify(this.trategyInfo[i])
            } else if (this.trategyInfo[i].type === 6) {
              if (!this.trategyInfo[i].templateId || this.trategyInfo[i].templateId.length === 0) {
                this.saveLoading = false
                return this.$message.error(this.$t('annual_maintenance_strategy_template'))
              }
              if (!this.trategyInfo[i].maintainIds || this.trategyInfo[i].maintainIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('annual_maintenance_person'));
              }
              if (!this.trategyInfo[i].checkIds || this.trategyInfo[i].checkIds.length === 0) {
                this.saveLoading = false;
                return this.$message.error(this.$t('annual_inspection_person'));
              }
              this.trategyInfo[i].maintainIds = this.trategyInfo[i].maintainIds.join(',');
              this.trategyInfo[i].checkIds = this.trategyInfo[i].checkIds.join(',');
              if (this.trategyInfo[i].day1 > this.trategyInfo[i].day2) {
                this.saveLoading = false
                return this.$message.error(this.$t('annual_maintenance_time_range'))
              }
              yearTemplateId = this.trategyInfo[i].templateId
              this.$delete(this.trategyInfo[i], 'templateId')
              this.$delete(this.trategyInfo[i], 'type')
              year = JSON.stringify(this.trategyInfo[i])
            }
          }
          this.info.strategy = JSON.stringify({
            day: day,
            week: week,
            halfmonth: halfmonth,
            month: month,
            quarter: quarter,
            halfyaer: halfyaer,
            year: year,
            dayTemplateId: dayTemplateId,
            weekTemplateId: weekTemplateId,
            halfmonthTemplateId: halfmonthTemplateId,
            monthTemplateId: monthTemplateId,
            quarterTemplateId: quarterTemplateId,
            halfyaerTemplateId: halfyaerTemplateId,
            yearTemplateId: yearTemplateId
          })
          if (this.info.maintainPerson.constructor === Array) {
            this.info.maintainPerson = this.info.maintainPerson.join(',')
            this.info.checkPerson = this.info.checkPerson.join(',')
          }
          const labels2 = this.$refs['myCascader1'].getCheckedNodes()[0].pathLabels
          const labels = this.$refs['myCascader'].getCheckedNodes()[0].pathLabels
          var brand = labels2[0]
          if (brand !== this.$t('other')) {
            this.info.infoName = labels.join('/') + '/' + labels2.join('/')
          } else {
            this.info.infoName = labels.join('/') + '/' + this.info.brandStr
            this.brandbool = true
          }
          this.info.groupIds = this.info.groupIds.join(',');
          API.createDevice(this.info)
            .then(response => {
              if (response.message.success) {
                this.saveLoading = false
                this.$message({
                  message: response.message.message,
                  showClose: true,
                  type: 'success'
                })
                this.$router.go(-1)
              } else {
                this.info.maintainPerson = this.info.maintainPerson.split(',')
                this.info.checkPerson = this.info.checkPerson.split(',')
                this.saveLoading = false
                this.$message({
                  message: response.message.message,
                  showClose: true,
                  type: 'error'
                })
              }
            })
            .catch(() => {
              // this.info.maintainPerson = this.info.maintainPerson.split(',')
              // this.info.checkPerson = this.info.checkPerson.split(',')
              this.saveLoading = false
            })
        } else {
          return false
        }
      })
    },
    updateDevice() {
      this.isUpdate = false
    }
  }
}
</script>
<style>
.widthTable {
  width: 1550px;
}
</style>
